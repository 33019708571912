import React, { Component } from "react";
import Swiper from "swiper";
import { ToastContainer, toast } from "react-toastify";

import "./index.css";
import "swiper/swiper-bundle.css";

export default class Home extends Component {

    constructor(props){
        super(props);
        console.log(this?.props?.location?.state?.nickname);
        this.state = {
            images: window?.config?.images || [
                "/images/huoying.jpg",
                "/images/lol.jpg",
                "/images/name.jpg",
                "/images/zhenji.jpg",
            ],
            nickname: this?.props?.location?.state?.nickname || '',
            index: 0
        };
    }

    render() {
        return (
            <div className="login-container">
                <ToastContainer />
                <div className="swiper-container" ref={el => this.c = el}>
                    <div className="swiper-wrapper">
                        {this.state.images.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className="swiper-slide"
                                    style={{ backgroundImage: `url(${item})` }}
                                >
                                    <div className="mask"></div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="login">
                    <div className="group">
                        <input
                            type="text"
                            placeholder="你的昵称"
                            onChange={this.change}
                            value={this.state.nickname}
                        />
                        <span className="highlight"></span>
                        <span className="bar"></span>
                    </div>
                    <div className="button -blue center" onClick={this.start}>
                        开&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;始
                    </div>
                </div>
            </div>
        );
    }

    change = (e) => {
        this.setState({
            nickname: e.target.value,
        });
    };

    start = () => {
        if(!this.state.nickname){
            toast.error(`昵称不能为空 !`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                closeButton: false,
                draggable: false
            });
            return;
        }
        //编写正则
        let reg = /^.{1,6}$/;
        if(!reg.test(this.state.nickname)){
            toast.error(`昵称不能超过 6 位`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                closeButton: false,
                draggable: false
            });
            return;
        }

        this.props.history.push({
            pathname: '/game',
            state: {
                nickname: this.state.nickname,
                image: this.state.images[this.state.index]
            }
        })
    }

    componentDidMount() {
        new Swiper(this.c, {
            loop: true, // 循环模式选项
            on: {
                slideChangeTransitionEnd: (swiper) => {
                    this.setState({
                        index: swiper.activeIndex - 1
                    })
                },
            },
        });
    }
}
