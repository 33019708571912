import React, { Component } from "react";
import "./index.css";
import { exchange } from "../../utils/func";
import anime from "animejs/lib/anime.es.js";
import _ from "lodash";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import BetterScroll from "better-scroll";
import axios from "axios";
import hash from 'object-hash'


export default class PinTu extends Component {
    //构造方法
    constructor(props) {
        super(props);

        let row = window?.config?.row || 5;
        let col = window?.config?.col || 3;
        this.state = {
            //行数与列数
            row,
            col,
            //宽度与高度
            width: 360,
            height: 640,
            duration: 100,
            easing: "easeInOutQuad",
            //数据信息
            info: [],
            //空白区的位置
            emptyPos: { x: row - 1, y: col - 1 },
            //图片地址
            image: "http://cdn.xiaohigh.com/girl.jpg",
            //是否正在移动中
            isMoving: false,
            //游戏是否正在进行中
            isStart: false,
            //是否结束
            isOver: false,
            //鼠标是否可以移动
            canMove: false,
            //方向
            direction: -1,
            //控制遮罩
            showMask: true,
            //排行榜
            ranks: [],
            //显示排行榜
            showRanks: false,
            //目标主机地址
            host: window.config.host || '',
        };
        //遍历生成初始化的 state 的值
        for (let i = 0; i < row; i++) {
            //初始化数组
            this.state.info[i] = [];
            //遍历
            for (let j = 0; j < col; j++) {
                this.state.info[i].push({
                    bg: { init_x: i, init_y: j },
                    id: Math.random(),
                });
            }
        }
        //获取数组
        let { info } = this.state;
    }

    //打乱二维数组
    shuffle = () => {
        //获取数据
        let {
            col,
            row,
            info,
            emptyPos: { x, y },
        } = this.state;
        //随机
        let times = window?.config?.complex || _.random(100, 500);
        for (let i = 0; i < times; i++) {
            //判断四个方向
            let dir = _.random(1, 4);
            //判断
            switch (dir) {
                //与左侧交换
                case 1:
                    //如果在最左侧
                    if (x === 0) continue;
                    exchange(info, x, y, x - 1, y);
                    x = x - 1;
                    break;
                //与上侧交换
                case 2:
                    if (y === 0) continue;
                    exchange(info, x, y, x, y - 1);
                    y -= 1;
                    break;
                // //与右侧交换
                case 3:
                    if (x >= row - 1) continue;
                    exchange(info, x, y, x + 1, y);
                    x += 1;
                    break;
                // //与下侧交换
                case 4:
                    if (y >= col - 1) continue;
                    exchange(info, x, y, x, y + 1);
                    y += 1;
                    break;
            }
        }
        //设置空白区的位置
        this.setState({
            info,
            emptyPos: { x, y },
        });
    };

    //鼠标事件
    mousedown = (e) => {
        //获取当前元素的位置
        let pos = e.target.getAttribute("flag");
        //空白格的位置
        let {
            emptyPos: { x, y },
            width,
            height,
            row,
            col,
        } = this.state;
        //获取四周的位置
        let arr = [
            `${x}-${y - 1}`,
            `${x - 1}-${y}`,
            `${x}-${y + 1}`,
            `${x + 1}-${y}`,
        ];
        //检查是否在可移动的坐标中
        let index = arr.findIndex((item) => item == pos);
        //判断
        if (index === -1) {
            this.state.canMove = false;
            return;
        } else {
            this.state.canMove = true;
            this.state.direction = index;
        }

        //当前位置
        let res = arr[index].split("-");
        let _x = res[0];
        let _y = res[1];

        //保存鼠标的位置
        this.x = e.pageX;
        this.y = e.pageY;
        this.moveEl = {
            el: e.target,
            //根据坐标计算起始位置
            left: _y * (width / col),
            top: _x * (height / row),
            time: Date.now(),
        };
    };

    mousemove = (e) => {
        //检查能否移动
        if (!this.state.canMove) return;
        //获取当前的位置
        let _x = e.pageX;
        let _y = e.pageY;
        //获取方向
        let { direction } = this.state;
        //判断方向
        switch (direction) {
            //向右移动
            case 0:
                //计算最小和最大的left
                var minLeft = this.moveEl.left;
                var maxLeft = this.moveEl.left + this.moveEl.el.offsetWidth;
                //判断移动方向
                var offsetX = _x - this.x;
                var newLeft = this.moveEl.left + offsetX;
                //判断
                if (newLeft <= minLeft) {
                    newLeft = minLeft;
                }
                if (newLeft >= maxLeft) {
                    newLeft = maxLeft;
                }

                this.moveEl.el.style.left = newLeft + "px";
                break;
            //向下滑动
            case 1:
                //计算最小和最大的left
                var mintop = this.moveEl.top;
                var maxtop = this.moveEl.top + this.moveEl.el.offsetHeight;
                //判断移动方向
                var offsetY = _y - this.y;
                var newtop = this.moveEl.top + offsetY;
                //判断
                if (newtop <= mintop) {
                    newtop = mintop;
                }
                if (newtop >= maxtop) {
                    newtop = maxtop;
                }

                this.moveEl.el.style.top = newtop + "px";
                break;
            //向左移动
            case 2:
                //计算最小和最大的left
                var minLeft = this.moveEl.left - this.moveEl.el.offsetWidth;
                var maxLeft = this.moveEl.left;
                //判断移动方向
                var offsetX = _x - this.x;
                var newLeft = this.moveEl.left + offsetX;
                //判断
                if (newLeft <= minLeft) {
                    newLeft = minLeft;
                }
                if (newLeft >= maxLeft) {
                    newLeft = maxLeft;
                }
                this.moveEl.el.style.left = newLeft + "px";
                break;

            //向上滑动
            case 3:
                //计算最小和最大的left
                var mintop = this.moveEl.top - this.moveEl.el.offsetHeight;
                var maxtop = this.moveEl.top;
                //判断移动方向
                var offsetY = _y - this.y;
                var newtop = this.moveEl.top + offsetY;
                //判断
                if (newtop <= mintop) {
                    newtop = mintop;
                }
                if (newtop >= maxtop) {
                    newtop = maxtop;
                }

                this.moveEl.el.style.top = newtop + "px";
                break;
        }
    };

    mouseup = (e) => {
        if (!this.state.canMove) return;
        // this.state.canMove = false;
        //获取鼠标抬起的位置
        let _x = e.pageX;
        let _y = e.pageY;
        //获取方向
        let { direction, duration, easing } = this.state;
        //判断方向
        switch (direction) {
            //向右移动
            case 0:
                //判断移动的距离
                var offsetX = _x - this.x;
                //如果超过一半 则向右移动
                if (
                    Math.abs(offsetX) >= this.moveEl.el.offsetWidth / 3 ||
                    Date.now() - this.moveEl.time <= 200
                ) {
                    this.moveRight();
                } else {
                    anime({
                        targets: this.moveEl.el,
                        left: {
                            duration,
                            easing,
                            value: this.moveEl.left + "px",
                        },
                    });
                }
                break;

            //向下移动
            case 1:
                //判断移动的距离
                var offsetY = _y - this.y;
                //如果超过一半 则向右移动
                if (
                    Math.abs(offsetY) >= this.moveEl.el.offsetHeight / 3 ||
                    Date.now() - this.moveEl.time <= 200
                ) {
                    this.moveDown();
                } else {
                    anime({
                        targets: this.moveEl.el,
                        top: {
                            duration,
                            easing,
                            value: this.moveEl.top + "px",
                        },
                    });
                }
                break;

            //向左移动
            case 2:
                //判断移动的距离
                var offsetX = _x - this.x;
                //如果超过一半 则向右移动
                if (
                    Math.abs(offsetX) >= this.moveEl.el.offsetWidth / 3 ||
                    Date.now() - this.moveEl.time <= 200
                ) {
                    this.moveLeft();
                } else {
                    anime({
                        targets: this.moveEl.el,
                        left: {
                            duration,
                            easing,
                            value: this.moveEl.left + "px",
                        },
                    });
                }
                break;

            //向上移动
            case 3:
                //判断移动的距离
                var offsetY = _y - this.y;
                //如果超过一半 则向右移动
                if (
                    Math.abs(offsetY) >= this.moveEl.el.offsetHeight / 3 ||
                    Date.now() - this.moveEl.time <= 200
                ) {
                    this.moveUp();
                } else {
                    anime({
                        targets: this.moveEl.el,
                        top: {
                            duration,
                            easing,
                            value: this.moveEl.top + "px",
                        },
                    });
                }
                break;
        }
        this.state.canMove = false;
    };

    //手指触摸事件
    touchstart = (e) => {
        if (this.state.isMoving) return;
        this.state.isMoving = true;
        //获取当前元素的位置
        let pos = e.target.getAttribute("flag");
        //空白格的位置
        let {
            emptyPos: { x, y },
            width,
            height,
            row,
            col,
        } = this.state;
        //获取四周的位置
        let arr = [
            `${x}-${y - 1}`,
            `${x - 1}-${y}`,
            `${x}-${y + 1}`,
            `${x + 1}-${y}`,
        ];
        //检查是否在可移动的坐标中
        let index = arr.findIndex((item) => item == pos);
        //判断
        if (index === -1) {
            this.state.canMove = false;
            return this.setState({
                isMoving: false,
            });
        } else {
            this.state.canMove = true;
            this.state.direction = index;
        }

        //当前位置
        let res = arr[index].split("-");
        let _x = res[0];
        let _y = res[1];

        //保存鼠标的位置
        this.x = e.changedTouches[0].pageX;
        this.y = e.changedTouches[0].pageY;
        this.moveEl = {
            el: e.target,
            //根据坐标计算起始位置
            left: _y * (width / col),
            top: _x * (height / row),
            time: Date.now(),
        };
    };

    touchmove = (e) => {
        //检查能否移动
        if (!this.state.canMove) return;
        //获取当前的位置
        let _x = e.changedTouches[0].pageX;
        let _y = e.changedTouches[0].pageY;
        //获取方向
        let { direction } = this.state;
        //判断方向
        switch (direction) {
            //向右移动
            case 0:
                //计算最小和最大的left
                var minLeft = this.moveEl.left;
                var maxLeft = this.moveEl.left + this.moveEl.el.offsetWidth;
                //判断移动方向
                var offsetX = _x - this.x;
                var newLeft = this.moveEl.left + offsetX;
                //判断
                if (newLeft <= minLeft) {
                    newLeft = minLeft;
                }
                if (newLeft >= maxLeft) {
                    newLeft = maxLeft;
                }

                this.moveEl.el.style.left = newLeft + "px";
                break;
            //向下滑动
            case 1:
                //计算最小和最大的left
                var mintop = this.moveEl.top;
                var maxtop = this.moveEl.top + this.moveEl.el.offsetHeight;
                //判断移动方向
                var offsetY = _y - this.y;
                var newtop = this.moveEl.top + offsetY;
                //判断
                if (newtop <= mintop) {
                    newtop = mintop;
                }
                if (newtop >= maxtop) {
                    newtop = maxtop;
                }

                this.moveEl.el.style.top = newtop + "px";
                break;
            //向左移动
            case 2:
                //计算最小和最大的left
                var minLeft = this.moveEl.left - this.moveEl.el.offsetWidth;
                var maxLeft = this.moveEl.left;
                //判断移动方向
                var offsetX = _x - this.x;
                var newLeft = this.moveEl.left + offsetX;
                //判断
                if (newLeft <= minLeft) {
                    newLeft = minLeft;
                }
                if (newLeft >= maxLeft) {
                    newLeft = maxLeft;
                }
                this.moveEl.el.style.left = newLeft + "px";
                break;

            //向上滑动
            case 3:
                //计算最小和最大的left
                var mintop = this.moveEl.top - this.moveEl.el.offsetHeight;
                var maxtop = this.moveEl.top;
                //判断移动方向
                var offsetY = _y - this.y;
                var newtop = this.moveEl.top + offsetY;
                //判断
                if (newtop <= mintop) {
                    newtop = mintop;
                }
                if (newtop >= maxtop) {
                    newtop = maxtop;
                }

                this.moveEl.el.style.top = newtop + "px";
                break;
        }
    };

    touchend = (e) => {
        e.preventDefault();

        if (!this.state.canMove) return;
        // this.state.canMove = false;
        //获取鼠标抬起的位置
        let _x = e.changedTouches[0].pageX;
        let _y = e.changedTouches[0].pageY;
        //获取方向
        let { direction, duration, easing } = this.state;
        //判断方向
        switch (direction) {
            //向右移动
            case 0:
                //判断移动的距离
                var offsetX = _x - this.x;
                //如果超过一半 则向右移动
                if (
                    Math.abs(offsetX) >= this.moveEl.el.offsetWidth / 3 ||
                    Date.now() - this.moveEl.time <= 200
                ) {
                    this.moveRight();
                } else {
                    anime({
                        targets: this.moveEl.el,
                        left: {
                            duration,
                            easing,
                            value: this.moveEl.left + "px",
                        },
                        complete: () => {
                            this.setState({
                                isMoving: false,
                            });
                        },
                    });
                }
                break;

            //向下移动
            case 1:
                //判断移动的距离
                var offsetY = _y - this.y;
                //如果超过一半 则向右移动
                if (
                    Math.abs(offsetY) >= this.moveEl.el.offsetHeight / 3 ||
                    Date.now() - this.moveEl.time <= 200
                ) {
                    this.moveDown();
                } else {
                    anime({
                        targets: this.moveEl.el,
                        top: {
                            duration,
                            easing,
                            value: this.moveEl.top + "px",
                        },
                        complete: () => {
                            this.setState({
                                isMoving: false,
                            });
                        },
                    });
                }
                break;

            //向左移动
            case 2:
                //判断移动的距离
                var offsetX = _x - this.x;
                //如果超过一半 则向右移动
                if (
                    Math.abs(offsetX) >= this.moveEl.el.offsetWidth / 3 ||
                    Date.now() - this.moveEl.time <= 200
                ) {
                    this.moveLeft();
                } else {
                    anime({
                        targets: this.moveEl.el,
                        left: {
                            duration,
                            easing,
                            value: this.moveEl.left + "px",
                        },
                        complete: () => {
                            this.setState({
                                isMoving: false,
                            });
                        },
                    });
                }
                break;

            //向上移动
            case 3:
                //判断移动的距离
                var offsetY = _y - this.y;
                //如果超过一半 则向右移动
                if (
                    Math.abs(offsetY) >= this.moveEl.el.offsetHeight / 3 ||
                    Date.now() - this.moveEl.time <= 200
                ) {
                    this.moveUp();
                } else {
                    anime({
                        targets: this.moveEl.el,
                        top: {
                            duration,
                            easing,
                            value: this.moveEl.top + "px",
                        },
                        complete: () => {
                            this.setState({
                                isMoving: false,
                            });
                        },
                    });
                }
                break;
            default:
                this.state.isMoving = false;
        }
        this.state.canMove = false;
    };

    render() {
        //获取 info 数据  x y 确定位置   init_x init_y 确定背景位置
        let {
            info,
            width,
            height,
            row,
            col,
            image,
            emptyPos,
            isStart,
            showMask,
            ranks,
            showRanks,
        } = this.state;
        return (
            <div className="pintu-container">
                <ToastContainer />
                <div
                    className="img-container"
                    style={{
                        width: width + "px",
                        height: height + "px",
                    }}
                >
                    <div className={"rank " + (showRanks ? "show" : "")}>
                        <h2>排行榜</h2>
                        <div className="content" ref={(el) => (this.rank = el)}>
                            <ul>
                                {ranks.map((item, index) => {
                                    return (
                                        <li
                                            key={index}
                                            className={
                                                item.current ? "active" : ""
                                            }
                                            ref={(el) => {
                                                if (item.current) {
                                                    this.current = el;
                                                }
                                            }}
                                        >
                                            <span> {index + 1}</span>
                                            <span className="name">
                                                {" "}
                                                {item.nickname}
                                            </span>
                                            <span> {item.time}</span>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <div className="btn again" onTouchStart={
                            this.restart
                        } onClick={this.restart}>再来一局</div>
                    </div>

                    {!isStart && (
                        <div
                            id="start"
                            onClick={this.start}
                            onTouchStart={this.start}
                        >
                            开始游戏
                        </div>
                    )}
                    {showMask && <div className="mask"></div>}
                    {info.map((item, x) => {
                        return item.map((it, y) => {
                            let {
                                bg: { init_x, init_y },
                            } = it;
                            return (
                                <div
                                    ref={(el) => (this[`${x}-${y}`] = el)}
                                    flag={`${x}-${y}`}
                                    key={it.id}
                                    className="item"
                                    onMouseDown={this.mousedown}
                                    onMouseMove={this.mousemove}
                                    onMouseUp={this.mouseup}
                                    onTouchStart={this.touchstart}
                                    onTouchMove={this.touchmove}
                                    onTouchEnd={this.touchend}
                                    style={{
                                        backgroundSize: `${width}px ${height}px`,
                                        backgroundPosition: `${
                                            (-width / col) * init_y
                                        }px ${(-height / row) * init_x}px`,
                                        backgroundImage:
                                            row - 1 === init_x &&
                                            col - 1 === init_y
                                                ? ""
                                                : `url(${image})`,
                                        left: `${(width / col) * y}px`,
                                        top: `${(height / row) * x}px`,
                                        width: 100 / col + "%",
                                        height: 100 / row + "%",
                                    }}
                                >
                                    {/* {it.id.toString().slice(0, 4)} */}
                                </div>
                            );
                        });
                    })}
                </div>
            </div>
        );
    }

    //游戏开始
    start = () => {
        //打乱二维数组, 必须在成功的基础上移动, 否则会出现拼接异常
        this.shuffle();
        //修改状态
        this.setState({
            isStart: true,
            showMask: false,
            sTime: moment(Date.now()),
        });
    };

    restart = () => {
        this.props.history.replace({
            pathname: '/',
            state: {
                nickname: this.state.nickname
            }
        });
    }

    async componentDidMount() {
        //判断是否存在 nickname
        let nickname = this.props?.location?.state?.nickname;
        if (!nickname) {
            return this.props.history.push("/");
        }

        //设置状态
        this.setState({
            nickname,
            image:
                this.props.location.state.image ||
                "http://cdn.xiaohigh.com/girl.jpg",
        });
        window.addEventListener("keydown", this.keyDown);
        window.addEventListener("touchstart", this.prevent, {
            passive: false,
        });
        try {
            //获取数据
            let result = await axios(
                this.state.host + "/records?_sort=time&_order=asc"
            );
            //设置
            this.setState({
                ranks: result.data,
            });
        } catch (e) {}

        this.bs = new BetterScroll(this.rank);
    }

    prevent = function (e) {
        e.preventDefault();
    };

    //键盘处理程序
    keyDown = (e) => {
        if (this.state.isMoving) return;
        this.state.isMoving = true;
        //获取按键码
        let { keyCode } = e;
        //判断
        switch (keyCode) {
            //左键
            case 37:
                this.moveLeft();
                break;
            //上键
            case 38:
                this.moveUp();
                break;
            //右键
            case 39:
                this.moveRight();
                break;
            //下键
            case 40:
                this.moveDown();
                break;
            default:
                this.setState({
                    isMoving: false,
                });
        }
    };

    //向左
    moveLeft = () => {
        let {
            info,
            col,
            emptyPos: { x, y },
            width,
            duration,
            easing,
        } = this.state;
        //判断
        if (y >= col - 1)
            return this.setState({
                isMoving: false,
            });
        //交换
        exchange(info, x, y, x, y + 1);

        //获取空白元素
        let emptyEle = this[`${x}-${y}`];
        //移动元素
        anime({
            targets: emptyEle,
            left: {
                duration,
                value: (width / col) * (y + 1) + "px",
                easing,
            },
        });
        //另一个元素
        let otherEle = this[`${x}-${y + 1}`];
        //移动元素
        anime({
            targets: otherEle,
            left: {
                duration,
                value: (width / col) * y + "px",
                easing,
            },
            complete: () => {
                //修改状态
                this.setState({
                    info,
                    emptyPos: { x, y: y + 1 },
                    isMoving: false,
                });
                this.checkSuccess();
            },
        });
    };

    //向下
    moveDown = () => {
        let {
            info,
            emptyPos: { x, y },
            height,
            row,
            duration,
            easing,
        } = this.state;
        //判断
        if (x <= 0)
            return this.setState({
                isMoving: false,
            });
        //交换
        exchange(info, x, y, x - 1, y);

        //获取空白元素
        let emptyEle = this[`${x}-${y}`];
        //移动元素
        anime({
            targets: emptyEle,
            top: {
                duration,
                value: (height / row) * (x - 1) + "px",
                easing,
            },
        });
        // //另一个元素
        let otherEle = this[`${x - 1}-${y}`];
        anime({
            targets: otherEle,
            top: {
                duration,
                value: (height / row) * x + "px",
                easing,
            },
            complete: () => {
                //修改状态
                this.setState({
                    info,
                    emptyPos: { x: x - 1, y },
                    isMoving: false,
                });
                this.checkSuccess();
            },
        });
    };

    //向上
    moveUp = () => {
        let {
            info,
            emptyPos: { x, y },
            row,
            height,
            duration,
            easing,
        } = this.state;
        //判断
        if (x >= row - 1)
            return this.setState({
                isMoving: false,
            });
        //交换
        exchange(info, x, y, x + 1, y);

        //获取空白元素
        let emptyEle = this[`${x}-${y}`];
        //移动元素
        anime({
            targets: emptyEle,
            top: {
                duration,
                value: (height / row) * (x + 1) + "px",
                easing,
            },
        });
        //另一个元素
        let otherEle = this[`${x + 1}-${y}`];
        //移动元素
        anime({
            targets: otherEle,
            top: {
                duration,
                value: (height / row) * x + "px",
                easing,
            },
            complete: () => {
                //修改状态
                this.setState({
                    info,
                    emptyPos: { x: x + 1, y },
                    isMoving: false,
                });
                this.checkSuccess();
            },
        });
    };

    //向右
    moveRight = () => {
        let {
            info,
            emptyPos: { x, y },
            width,
            col,
            duration,
            easing,
        } = this.state;
        //判断
        if (y <= 0)
            return this.setState({
                isMoving: false,
            });
        //交换
        exchange(info, x, y, x, y - 1);
        //获取空白元素
        let emptyEle = this[`${x}-${y}`];
        //移动元素
        anime({
            targets: emptyEle,
            left: {
                duration,
                value: (width / col) * (y - 1) + "px",
                easing,
            },
        });
        //另一个元素
        let otherEle = this[`${x}-${y - 1}`];
        //移动元素
        anime({
            targets: otherEle,
            left: {
                duration,
                value: (width / col) * y + "px",
                easing,
            },
            complete: () => {
                //修改状态
                this.setState({
                    info,
                    emptyPos: { x, y: y - 1 },
                    isMoving: false,
                });
                this.checkSuccess();
            },
        });
    };

    //移除按键事件
    componentWillUnmount() {
        window.removeEventListener("keydown", this.keyDown);
        window.removeEventListener("touchstart", this.prevent, {
            passive: false,
        });
    }

    //检查是否成功
    async checkSuccess() {
        //判断数据 是否已经成功
        let { info, host, nickname, ranks } = this.state;
        let status = true;
        for (let i = 0; i < info.length; i++) {
            for (let j = 0; j < info[i].length; j++) {
                //如果存在一个不满足条件的情况, 设定为失败
                if (info[i][j].bg.init_x !== i || info[i][j].bg.init_y !== j) {
                    status = false;
                }
            }
        }

        //获取最终时间
        let eTime = moment(Date.now());
        //判断
        if (status) {
            //计算时间差
            let diffTime = eTime.diff(this.state.sTime, "seconds", true);
            
            //发送请求
            try {
                let data = {
                    nickname,
                    time: diffTime,
                };
                //写入签名
                data.sign = hash(data);
                await axios.post(host + "/records", data);
                //将数据插入到 ranks 中
                ranks.push({ ...data, current: true });
                //排序
                let res = ranks.sort((a, b) => {
                    if (a.time > b.time) return 1;
                    if (a.time < b.time) return -1;
                    return 0;
                });
                //设置状态
                this.setState(
                    {
                        isOver: true,
                        showMask: true,
                        showRanks: true,
                        ranks: res,
                    }, () => {
                        this.bs.refresh();
                        //当前元素距离顶部的偏移量
                        let t = this.current.offsetTop;
                        let h = this.rank.offsetHeight / 2;
                        let newTop = t - h + this.current.offsetHeight;
                        setTimeout(() => {
                            this.bs.scrollTo(0, -newTop, 1000);
                        }, 300)
                    }
                );

                toast.success(`恭喜过关, 所用时间: ${diffTime} 秒`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000,
                    closeButton: false,
                    draggable: false,
                });
                
            } catch (e) {
                toast.error(e.response.data.msg, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000,
                    closeButton: false,
                    draggable: false,
                });
                this.setState(
                    {
                        isOver: true,
                        showMask: true,
                        showRanks: true,
                    })
            }
        }
    }

}
