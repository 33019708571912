import PinTu from './components/PinTu';
import Home from './components/Home';
// import Test from './components/Test';
import 'normalize.css';
import { Route, Switch,Redirect } from 'react-router-dom';
// import './utils/rem';

function App() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/game" component={PinTu} />
      <Redirect to="/" />
    </Switch>
  );
}

export default App;
